import React from 'react';

import '../../../styles/scss/style.scss'

export const Bundle = () => {

  const linkStyle = {
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    borderRadius:"0.5rem", 
    background:"#e9ecef"
  }

  return (
    <div className="container">
      <div className="feature feature-2">
        <a href="/bundle" className="card text-center mx-auto" style={linkStyle}>
          <div className="card-body text-left text-dark" style={{ padding: "0px 8%" }}>
            <h2 className="my-5">Contrast Bundle</h2>
            <p className="my-5"> Get the React, Angular and Bootstrap 5 Premium UI kit Library Packages here + Premium Admin Templates  </p>
            <span className="mt-4 font-weight-bolder line-through"><del>$590.00</del> <span className="text-success">Save up to 80%</span></span>
            <div className="mt-1 mb-3"> <h2 className="h1 text-primary">$109.00</h2></div>
            <button className="btn btn-primary">Get Offer</button>
          </div>
          <div style={{width:"60%"}}>
            <img
              src={require('../../../components/images/adminheropro.png')}
              style={{height:"25rem", width:"100%", objectFit:"cover"}}
              className="mx-auto"
              alt="#"
            />
          </div>
        </a>
      </div>
    </div>
  );
};
