import React from 'react';
import styled from 'styled-components';
import Countdown from 'react-sexy-countdown';

import '../../../styles/scss/style.scss'

const CountdownWrapper = styled.div`
  margin-top: 3rem;
  color: #f44336;
  .react-countdown-container {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .react-countdown-element {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 2rem;
  }
  .react-countdown-time {
    font-weight: bold;
  }
  .react-countdown-time-text {
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const CountdownTimer = () => {

  return (
    <CountdownWrapper>
      <Countdown
        date="2021-12-24T12:00:00+07:00"
        onEndCountdown={ (count) => console.log(count) }
        lang="en"
        displayText={{
          Days: 'Days',
          Day: 'Day',
          Hours: 'Hours',
          Min: 'Min',
          Sec: 'Sec',
        }}
        isDayDoubleZero={ true }
      />
    </CountdownWrapper>
  );
};
