import React from 'react';
import { Link } from 'gatsby';

import '../../../styles/scss/style.scss'

export const Hero = () => {

  return (
    <div className="" style={{minHeight:"calc(100vh - 85px)", marginTop:"-40px"}}>
      <div  className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header" style={{marginBottom: "25px", color:"white"}}>
              <h1 className="">
                 Limited Time Sale
              </h1>
              
              <h2 className="hero__lead">
                Bootstrap UI kit Library + PRO Admin template
              </h2>

              <p className="hero__text">
                Get Amazing deals on our premium products during our sale. This will only last for 72 hours.
              </p>
              <div className="hero__buttons">
                {/* <Link href="https://windframe.devwares.com" className="btn1 hero__btn btn__inverse">
                  <span className="btn__text">Create a website</span>
                </Link> */}
              </div>
            </div>
            <div className="hero__image" style={{width:  "90%"}}>
              <img alt="summer sale bootstrap" style={{width: "inherit"}} src={require('../../../components/images/heroImage.png')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
