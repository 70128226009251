import React from 'react';
import Helmet from 'react-helmet';

import FAQ from '../../components/Sales/React/Faq';
import { Layout } from '../../components/DarkLayout/Layout';
import { Hero } from '../../components/Sales/React/Hero';
import { Hero2 } from '../../components/Sales/React/Hero2';
import { Hero3 } from '../../components/Sales/React/Hero3';
import { Hero4 } from '../../components/Sales/React/Hero4';
import { Bundle } from '../../components/Sales/React/Bundle';
// import { BundleWindframe } from '../../components/Sales/React/BundleWindframe';
import { CountdownTimer } from '../../components/Sales/React/CountdownTimer';


const Sales = () => {

  return (
    <Layout>
      <Helmet>
        <title>Short Winter Sales - Bootstrap UI Kit Library and admin template</title>
        <meta
          name="title"
          content="Short Winter Sales - Bootstrap UI Kit Library and admin template"
        />
        <meta
          name="description"
          content="Get Contrast Bootstrap UI Kit Library and admin template at a discounted price for only 72 hours"
        />
        <meta
          property="og:title"
          content="Short Winter Sales - Bootstrap UI Kit Library and admin template"
        />

        <meta
          property="og:description"
          content="Get Contrast Bootstrap UI Kit Library and admin template at a discounted price for only 72 hours"
        />
        <meta
          property="twitter:title"
          content="Short Winter Sales - Bootstrap UI Kit Library and admin template"
        />
        <meta
          property="twitter:description"
          content="Get Contrast Bootstrap UI Kit Library and admin template at a discounted price for only 72 hours"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <div>
        <CountdownTimer />
        <Hero/>
        <Hero2 dark/>
        <Hero3 dark/>
        <Hero4 dark/>
        <Bundle />
        {/* <BundleWindframe/> */}
        {/* <FAQ dark/> */}
      </div>
    </Layout>
  );
};

export default Sales;
