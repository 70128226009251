export const questions = [
  {
    id: 1,
    question: 'Does the Contrast PRO library come seperate from the template',
    answer: 'Yes in each of the products you will get the pro librray which contains all the diffrent components and a guide on how to use it in your project. You will also get a seperate folder with the PRO admin temlate which you can use to immediately kick off a project',
  },
  {
    id: 2,
    question: 'How can I get support for the products',
    answer: 'You can easily reach out to us by sending a mail to contact@devwares.com or you can reach us through our contact form by clicking the contact link in the navigation above',
  },
  {
    id: 3,
    question: 'Do I get documentation for the products',
    answer: 'They are well detailed documentations for each of the products with well tested code and contant that is continually being updated. You can check out the documentaion page by heading to the docs link in the navigation above',
  },

]
